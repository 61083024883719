import React from 'react';
import Man1 from 'images/men/Marc.svg';
import Man2 from 'images/men/Fabien.svg';
import Man3 from 'images/men/Richard.svg';
import { mainMedicalTeam } from 'components/common/data/doctors';
import config from 'utils/siteConfig';

export const scientists = {
  team: mainMedicalTeam,
  title: 'Notre comité scientifique ',

};

export const testimonies = [
  {
    testimony: (
      <span>
        Je dois dire que je galère depuis cinq ans en ne comprenant pas ce qui
        se passe, et surtout en ne sachant pas comment m&apos;en sortir. Vous
        avez su identifier mes besoins très efficacement. Je vous tiendrai
        informé de la suite des opérations. C&apos;est à moi de jouer, mais vous
        avez su me donner la feuille de route. Merci infiniment.
      </span>
    ),
    patient: 'Marc',
    city: '',
    age: '42 ans',
    Icon: Man1,
    backgroundColorClass: 'lc-light-blue',
  },
  {
    testimony:
      "Après avoir acheté pas mal de produits supposés miracles sur Internet et m'être fait arnaquer je vous remercie d'avoir créer une plateforme sûre et qui marche, avec des vrais médecins spécialistes et des vrais pharmaciens.",
    patient: 'Fabien',
    city: '',
    age: '30 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
  },
  {
    testimony:
      "J'ai pris et effectué un rendez-vous avec l'un de vos spécialistes. Je trouve l'idée et l'expérience du site vraiment géniales, c'est un moyen de traiter un sujet délicat sans aucune gêne ni complexe, merci à vous !",
    patient: 'Richard',
    city: 'Montpellier',
    age: '51 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
  },
];

export const sections = [
  {
    title: 'Comment se déroule une téléconsultation ?',
    body: (
      <div>
        <p>
          La téléconsultation chez Charles.co se déroule donc de cette manière :
          en quelques clics, vous répondrez à un questionnaire médical complet,
          visible uniquement par le médecin, lui permettant ainsi de connaître
          votre état de santé général.
        </p>
        <p>
          Mis en relation ensuite avec un médecin sexologue français, vous
          pourrez exprimer librement vos troubles sexuels par 3 modes de
          téléconsultation :
        </p>
        <ul>
          <li>Soit par téléphone</li>
          <li>Par messagerie</li>
          <li>Ou par visioconférence</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Quels sont les avantages de la téléconsultation sur Charles.co ?',
    body: (
      <div>
        <p>Les avantages de la téléconsultation chez Charles.co :</p>
        <ul>
          <li>
            Une consultation à un tarif abordable : {config.consultationPrice} € (en moyenne 60 ou 80 €
            en cabinet) et un rendez-vous dans les 24H (les délais d’attente en
            cabinet varient de plusieurs mois)
          </li>
          <li>
            Un diagnostic médical de qualité avec des spécialistes de la
            sexologie et la délivrance d’une ordonnance si nécessaire
          </li>
          <li>
            La possibilité de se faire livrer des médicaments ou de les retirer
            en pharmacie
          </li>
          <li>
            Et surtout, un suivi médical avec un compte rendu détaillé de votre
            consultation, accessible dans un espace sécurisé
          </li>
        </ul>
      </div>
    ),
  },
];
