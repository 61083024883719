import React from 'react';
const metaData = {
  title: 'Notre approche médicale et comité scientifique | Charles.co',
  description: 'Notre comité scientifique rassemble des experts dans la santé sexologie, pychologie, sommeil, digestion et micro-nutrition pour vous accompagner. '
}
export const treatments = {
  title: 'Nos traitements de l’éjaculation précoce',
  text: 'En fonction de votre profil et vos symptômes votre médecin sexologue peut vous recommander un plan de traitements comprenant : ',
  list: [
    {
      fullName: 'Téléconsultations',
      description: 'Nos médecins spécialistes et thérapeutes sont disponibles 7 jours sur 7 pour vous écouter, diagnostiquer vos problématiques, interpréter vos analyses sanguines si besoin et vous recommander le plan de traitement le plus adapté à votre situation',
      pictures: {
        regular: 'new-images/iphone-mockup-1.png',

      },
    },
    {
      fullName: 'Médicaments',
      description: 'Les médecins prescrivent si besoin des médicaments à l\'efficacité prouvés qui peuvent être livrés à domicile depuis des pharmacies françaises',
      pictures: {
        regular: 'new-images/pills-in-ramekin.jpeg',
        alt: "pillules-dans-un-ramequin",
        title: "pillules dans un ramequin"

      },
    },
    {
      fullName: 'Phytothérapie',
      description: 'Les médecins peuvent recommander des phytothérapies pour améliorer un point précis de la santé du patient.',
      pictures: {
        regular: 'new-images/pill-boxes.png',
        alt: "boite-a-pillules",
        title: "boite a pillules"
      },
    },
    {
      fullName: 'Thérapies digitales',
      description: 'Nos médecins ont développé pour certaines pathologies des thérapies comportementales digitales innovantes s\'étalant sur plusieurs semaines qui permettent, en travaillant les aspects physique et mentaux, de résoudre durablement les problématiques des patients.',
      pictures: {
        regular: 'new-images/iphone-mockup-wireless-charge.jpeg',
        alt: "therapies-comportementales-digitales",
        title: "therapies comportementales digitales"
      },
    },
    {
      fullName: 'Autres produits',
      description: 'La résolution d\'un problème de santé précis passe parfois par des dispositifs médicaux, produits ou objets spécifiques que les médecins connaissent et peuvent recommander dans le cadre du plan de traitement',
      pictures: {
        regular: "new-images/gilbert-tele-consult.jpg",
      },
    },
  ],
};

export default {
  treatments,
  metaData,
};
